<template>
  <v-container class="fill-height align-center pt-0 px-0">
    <v-skeleton-loader class="pa-0 mt-3" style="width: 100%" v-if="loading" type="image"></v-skeleton-loader>

    <v-fade-transition>
      <div v-show="!loading" style="width: 100%">
        <v-alert type="info" v-if="alert != 'unset'" class="my-2" text>

          <template v-if="alert == 1">
            Falta 1 dia para que termine su suscripción.
          </template>
          <template v-else-if="alert > 1">
            Faltan menos de {{ alert }} dias para que termine su suscripción.
          </template>
          <template v-else>
            Tu suscripción vence hoy.
          </template>

          <v-btn @click="$router.push('/pago')" class="mt-1">
            <v-icon left color="success">mdi-credit-card-outline</v-icon>
            Extender suscripción
          </v-btn>
        </v-alert>
        <v-row class="justify-center" no-gutters v-if="!workingOut && !$props.userObject">
          <v-col cols="12">

            <v-card elevation="0" color="transparent" class="mt-3" v-if="!workingOut && !$props.userObject">
              <h2 v-if="inscription && !hideFinish" class="text-center mt-4">
                <template v-if="inscription.finished">
                  <v-icon class="mr-3">mdi-calendar</v-icon> La clase ya ha terminado!
                </template>
                <template v-else-if="inscription.started">
                  <v-icon class="mr-3">mdi-calendar</v-icon>La clase ya comenzo!!
                </template>
                <template v-else>
                  <!--   <template v-if="inscription && inscription.type != 'base'">
                            <br>  {{ inscription.type }} <br><br>
                        </template> -->
                  <h3 class="text-h5 text-center mb-12">
                    <v-alert v-if="inscription && !hideFinish && !workingOut && !successQR && checkToday" type="error"
                      text class="text-left">
                      Estas llegando tarde, recuerda cancelar tu reserva con tiempo si no puedes asistir.
                    </v-alert>



                    <v-icon class="mr-3">mdi-clock</v-icon> {{ returnText }}
                  </h3>
                  <QR :successQR="successQR" v-if="qrCodeEncrypted" :qrCodeEncrypted="qrCodeEncrypted" />
                </template>
              </h2>
              <template v-else>
                <v-card-text class="text-center text-h1" v-if="!$props.userObject && isSameDay">
                  {{ workoutsCompleted }}
                </v-card-text>


                <v-card-text class="text-center text-h6" v-if="!$props.userObject && isSameDay">
                  {{ workoutsCompleted == 1 ? 'Día completado' : 'Días completados' }} esta semana
                </v-card-text>
              </template>

              <v-card-actions v-if="inscription && !hideFinish && !workingOut && !inscription.started && !successQR">
                <v-spacer></v-spacer>
                <v-btn dark class="my-3 mt-10 mx-auto" color="red" x-large :loading="deleting"
                  @click="deleteInscription" rounded block
                  :disabled="inscription && !hideFinish && !workingOut && !successQR && checkToday">
                  <v-icon left>mdi-close</v-icon>
                  Cancelar reserva
                </v-btn>
                <v-spacer></v-spacer>

              </v-card-actions>



            </v-card>


          </v-col>
        </v-row>
        <v-row class="justify-center " v-if="hideFinish == true && workingOut == false">
          <v-col cols="12" sm="12">
            <v-card class="text-center" color="transparent" elevation="0">
              <v-card-text class="pa-0">
                <!--  <v-slider v-if="user" readonly :tick-labels="steps" :disabled="workoutsCompleted == 0"
                  :value="workoutsCompleted" min="1" :max="user.plan" thumb-label="always" thumb-color="success"
                  color="success" hide-details="">
                  <template #thumb-label="props">
                    <v-icon dark>
                      {{ season(props.value) }}
                    </v-icon>
                  </template>
                </v-slider> -->

                <v-stepper class="pa-0 transparent" elevation="0" v-show="workoutsCompleted > -1" alt-labels
                  v-if="['superuser', 'admin', 'entrenador'].includes($store.state.Auth.token.claims.type)">


                  <v-stepper-header class="pa-0">

                    <template v-for="i in user.plan">
                      <v-stepper-step :complete="i <= workoutsCompleted" :step="i" complete-icon="mdi-fire"
                        color="#fe8200" :key="i + 'dem'">
                        #{{ i }}
                      </v-stepper-step>

                      <v-divider v-if="i < user.plan"
                        v-bind:class="{ 'complete': i < workoutsCompleted, 'incomplete': i > workoutsCompleted }"></v-divider>
                    </template>

                  </v-stepper-header>
                </v-stepper>



              </v-card-text>


              <v-card-actions class="text-left pa-0">


                <template v-if="user && workoutsCompleted == user.plan">
                  <v-alert type="success" class="ma-0" width="100%" text>
                    Felicitaciones completaste tu plan!
                  </v-alert>


                </template>
                <template v-else-if="hideFinish">
                  <v-alert type="success" class="ma-0" width="100%" text>
                    Felicitaciones completaste tu dia {{ workoutsCompleted }}. Recuerda reservar tu horario para
                    mañana.
                  </v-alert>
                </template>
                <template v-else-if="workoutsCompleted == 0 && !inscription">
                  <v-icon left color="red">mdi-close</v-icon> No has completado ningún día de tu plan
                </template>
                <template
                  v-else-if="user && workoutsCompleted < user.plan && !hideFinish && inscription && !workingOut">
                  <v-btn color="primary" @click="finishDailyWorkout()" block>
                    Finalizar día #{{ workoutsCompleted + 1 }}
                  </v-btn>
                </template>
                <template
                  v-else-if="user && workoutsCompleted > 0 && workoutsCompleted != user.plan && !hideFinish && !workingOut">
                  <v-icon color="orange">mdi-alert</v-icon> Te faltan {{ user.plan - workoutsCompleted }} días
                  para completar tu plan
                </template>
              </v-card-actions>
            </v-card>
            <template v-if="!hideFinish && inscription">

              <!--  <v-dialog v-model="dialogCrono" fullscreen>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>
                      <v-icon>mdi-clock</v-icon> Tiempos
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogCrono = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <ChronoApp></ChronoApp>
                </v-card>
              </v-dialog> -->
            </template>
          </v-col>
        </v-row>
      </div>

    </v-fade-transition>
  </v-container>
</template>



<script>
import { getFirestore, collection, query, where, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp } from "firebase/firestore";
import ChronoApp from '@/views/ChronoApp.vue';
import QR from '@/components/QR.vue'
import moment from 'moment'

import LoginManager from './../models/login';


export default {

  watch: {
    successQR: function (val) {
      if (val) {
        this.finishDailyWorkout();
      }
    },
    date: function (val) {
      if (val) {
        this.checkWorkout();

        let now = moment()
        let date = moment(val, 'YYYY-MM-DD')
        this.isSameDay = now.isSame(date, 'day');

      }
    },
    inscription: function (val) {
      this.deleting = false;
    }
  },
  components: {
    ChronoApp, QR
  },
  props: {
    inscription: {
      type: Object
    },
    qrCodeEncrypted: {
      type: String,
      default: null
    },
    successQR: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: null
    },
    userObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isSameDay: true,
    routine: null,
    alert: 'unset',
    workingOut: false,
    step: 0,
    workoutPlan: null,
    dialogCrono: false,
    loading: true,
    workoutDoc: null,
    user: null,
    workoutsCompleted: 0,
    workoutDates: null,
    hideFinish: false,
    toggleTrackColor: false,
    deleting: false,
    steps: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6'
    ],
    icons: [
      'mdi-check',
      'mdi-check',
      'mdi-check',
      'mdi-check',
      'mdi-check',
      'mdi-check',
      'mdi-check'
    ],
    workedOutToday: false
  }),
  computed: {
    animatedTrackColor() {
      return this.toggleTrackColor ? "orange lighten-2" : "orange lighten-5";
    },
    canGoToNextStep() {
      const currentExercise = this.workoutPlan.exercises[this.step];
      return currentExercise ? currentExercise.completed : false;
    },
    returnText() {
      let date = this.inscription.date
      let dateMoment = moment(date, 'YYYY-MM-DD HH:mm:ss')
      let checkToday = moment().isSame(dateMoment, 'day');

      // check if its today and its if insicrption date already passed
      if (checkToday && moment().isAfter(dateMoment)) {
        return 'Hace ' + dateMoment.locale('es').fromNow(true) + ' / ' + moment(this.inscription.date, "YYYY-MM-DD HH:mm:ss").format('HH:mm') + 'hs'
      } else {
        return 'En ' + dateMoment.locale('es').fromNow(true) + ' / ' + moment(this.inscription.date, "YYYY-MM-DD HH:mm:ss").format('HH:mm') + 'hs'
      }
    },
    checkToday() {
      let date = this.inscription.date
      let dateMoment = moment(date, 'YYYY-MM-DD HH:mm:ss')
      let checkToday = moment().isSame(dateMoment, 'day');

      // check if its today and its if insicrption date already passed
      if (checkToday && moment().isAfter(dateMoment)) {
        return true
      } else {
        return false
      }
    },
  },
  async mounted() {

    if (this.$props.userObject) {
      this.user = this.$props.userObject
      this.user.plan = parseInt(this.user.plan) 

      this.user.plan = this.user.plan == 0 ? 6 : this.user.plan

      this.workoutsCompleted = this.user.workoutsCompleted
      this.loading = false;

      this.hideFinish = true;
      this.workingOut = false;

    }

    //a

    //await this.getWorkoutPlan();
  },

  created() {
    /*  setInterval(() => {
         this.toggleTrackColor = !this.toggleTrackColor;
     }, 1000); */
  },

  methods: {
    returnUser() {
      return this.user
    },
    returnWorkoutsCompleted() {
      return this.workoutsCompleted
    },

    async setAlert(type, date, description) {

      if (this.$props.user) {
        return;
      }


      const db = getFirestore();

      let user_id = this.$store.state.Auth.token.claims.user_id

      try {
        const timestampDate = Timestamp.fromDate(date);

        const newAlert = {
          user_id: user_id,
          type: type,
          date: timestampDate,
          description: description,
          seen: false
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        // now add to the alert user subcollection
        const userAlertsRef = collection(db, `users/${user_id}/alerts`);
        await addDoc(userAlertsRef, newAlert);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },
    async deleteInscription() {

      try {
        this.deleting = true;
        this.$emit('removeIncription');
      } catch (error) {
        console.log(error);
        this.deleting = false;
      }



    },
    checkWorkout() {
      if (!this.workoutDates) return;

      let today = moment()
      let date = moment(this.$props.date, 'YYYY-MM-DD')
      let isSame = today.isSame(date, 'day');
      if (this.workedOutToday && isSame) {
        this.hideFinish = true;
        return;
      }

    },
    setHideFinish(date, bool) {
      this.hideFinish = bool
    },
    nextStep() {
      if (this.canGoToNextStep) {
        this.step++;
      }
    },
    async finishDailyWorkout() {
      this.hideFinish = true;
      this.workingOut = false;
      this.workoutsCompleted += 1;
      this.workedOutToday = true;
      this.$emit('workedout')
      //check if plan is equal to worked out days and emit and event
      if (this.user.plan == this.workoutsCompleted) {
        this.$emit('planCompleted')
      }
    },
    season(val) {
      return this.icons[val]
    },
    async getUser() {
      const db = getFirestore()
      let user;
      if (this.$props.userObject) {
        user = this.$props.userObject.id;
      } else {
        user = this.$store.state.Auth.token.claims.user_id;
      }


      const docRef = doc(db, `users/${user}`);
      const docSnap = await getDoc(docRef);

      let data = docSnap.data()

      data.plan = parseInt(data.plan)

      if (data.plan == 0) {
        const db = getFirestore()
        let scheduleIntervalsRef = doc(db, `configurations/schedule`);
        let scheduleIntervals = await getDoc(scheduleIntervalsRef);
        let scheduleIntervalsData = scheduleIntervals.data()
        let maxDays = 0
        scheduleIntervalsData.Domingo.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Lunes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Martes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Miercoles.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Jueves.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Viernes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Sabado.length > 0 ? maxDays += 1 : null

        data.plan = maxDays
      }

      this.user = data

      if (data.disabled) {
        let today = moment().toDate()
        await this.setAlert('user_disabled', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Tu usuario ha sido deshabilitado, contacta con el administrador.",
        });

        this.logout();

      }

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          await this.setAlert('user_subscription_expired', today, null)

          if (!this.$props.userObject) {
            this.$router.push({ name: 'suscripcion' })
          }
        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true

          let diff = moment(endOfSubscription).diff(today, 'days');
          if (diff <= 5) {

            this.alert = diff;
          }
        }
      } else {

        if (['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(this.user.type) && !this.$props.userObject) {
          this.$router.push({ name: 'suscripcion' })
        }

      }

      let today = moment().toDate()

      //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
      if (this.user.licensePaused && !this.$props.userObject) {
        //await this.setAlert('user_license_paused', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
        });
        this.$router.push('/perfil')
      }

      //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
      // Query Firestore for licenses issued before today
      const licensesRef = collection(db, `users/${user}/licenses`);
      const q = query(licensesRef, where("issuedOn", "<", today));

      const licenseDocs = await getDocs(q);
      const filteredLicenses = licenseDocs.docs
        .map(doc => doc.data())
        .filter(license => {
          if (license.resumedOn) {
            return new Date(license.resumedOn.seconds * 1000) > today;
          } else if (typeof license.resumedOn === 'undefined') {
            return true;
          }

        }); // Filter by resumedOn in client

      if (filteredLicenses.length === 0) {
        //console.log('No matching documents.');
      } else {
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
        });
        if (!this.$props.userObject) {
          this.$router.push('/perfil')
        }
      }





    },

    logout() {
      if (!this.$props.userObject) {
        LoginManager.logout()
        this.$store.dispatch("Auth/userLogout");
        this.$router.push('/login')

      }

    },
    async getWorkoutsCompleted() {

      const db = getFirestore()
      let user_id;
      if (this.$props.userObject) {
        user_id = this.$props.userObject.id;
      } else {
        user_id = this.$store.state.Auth.token.claims.user_id;
      }
      const workoutsRef = collection(db, `users/${user_id}/workouts`);
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      const q = query(workoutsRef, where("weekStart", ">=", startOfWeek), where("weekStart", "<", endOfWeek));
      const workoutDocs = await getDocs(q);
      if (workoutDocs.empty) {
        this.workoutDoc = await addDoc(workoutsRef, { weekStart: startOfWeek, day: 0, dates: [] })
        this.workoutsCompleted = 0
        this.workoutDates = []
        // this.$emit('showActions')

      } else {
        this.workoutDoc = workoutDocs.docs[0]
        this.workoutsCompleted = this.workoutDoc.data().day
        this.workoutDates = this.workoutDoc.data().dates


        const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

        //check if plan is equal to worked out days and emit and event
        if (this.user.plan == this.workoutsCompleted) {
          this.hideFinish = true;
          //this.$emit('hideActions')
          this.$emit('workedout')
        } else {
          if (this.workoutDates.length > 0 && this.workoutDates[this.workoutDates.length - 1].substring(0, 10) == formattedDate.substring(0, 10)) {
            this.hideFinish = true;
            this.workedOutToday = true;
            this.$emit('workedout')
          } else {
            //this.$emit('showActions')
          }
        }
      }

      this.loading = false;
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
      return endOfWeek;
    },
    //  with firebase a method to get the workout plan for the user from the user company 'planifications' collection.

    //  with firebase a method to get the workout plan for the user from the user company 'planifications' collection.
    async getWorkoutPlan() {
      const db = getFirestore()

      if (this.user.routines && this.user.routines.length > 0) {
        let actual = this.user.routines.filter(elem => elem.actual)
        if (actual.length > 0) {
          this.user.planification = actual[0].name
        }

      } else {
        return;
      }

      const planificationsRef = doc(db, `planifications/${this.user.planification}`);

      const planificationDoc = await getDoc(planificationsRef);
      if (planificationDoc.exists() === false) {
        //console.log('No matching documents.');
        return;
      }

      console.log(planificationDoc.data())

      /*         let workOutDayPlan =   planificationDoc.data().weeks[0].days[this.workoutsCompleted];
              workOutDayPlan.exercises.forEach(elem=>{
                  elem.completed=false
              })
              this.workoutPlan =workOutDayPlan; */


    },

  }
}
</script>

<style>
.track-color-transition {
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 959.98px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: inline-block;
  }
}

.v-stepper--alt-labels .v-stepper__step {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 120px;
}

.theme--dark.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: rgb(138 138 138);
}
</style>
